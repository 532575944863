.App {
    text-align: center;
    display: flex;
    background-color: #282c34;
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0 ;
}

.sidemenu {
    width: 260px;
    padding: 10px;
    background-color: #202123;

}

.sidemenu-container {
    width: 100%;
    justify-content: flex-end;
}

.sidemenu-newtask {
    padding: 12px;
    border: 1px solid white;
    border-radius: 5px;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.newtasks-title  {
    padding: 4px 0 12px 0;
}

.newtasks-prompt {

}

.newtasks-select {
    width: 100%;
    display: flex;
}

.newtasks-select > select {
    flex: 1 0 100%;
}

.newtasks-description {
    margin: 8px 0 8px 0;
    width: 100%;
    height: 120px;
    background-color: #282c34;
    color: white;
}

.newtasks-button {
    margin: 8px;
    padding: 8px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: #dddddd;
    color: black;
    width: 100px;
}

.sidemenu-button {
}
.newtasks-button:hover {
    background-color:  #bbbbbb;
}

.chatbox {
    flex: 1;
    background-color: #343541;
    position: relative;
    overflow: auto;
}

.chat-input-holder {
    padding: 24px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
}

.chat-input-textarea {
    background-color: #40414f;
    color: white;
    font-size: 1.25em;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 12px;
    outline: none;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

.chat-log {
    text-align: left;
}

.chat-message.chatgpt {
    background-color: #444654;
}

.chat-message-center {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
}

.avatar {
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.avatar.chatgpt {
    background: #0da37f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.message {
    padding-left: 40px;
    padding-right: 40px;
    white-space: pre-wrap;
}



.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

